import React from "react";

import { mainHostUrl } from "@app/helpers";

import "./index.scss";

const themes = {
    grey: "--grey",
    light: "--light",
};

const HorizontalBanner = ({
    icon,
    title,
    actionText,
    description,
    className = "",
    square = false,
    actionUrl = "",
    theme = "light",
    noBorder = false,
    reapplyShown = false,
    supportSection = false,
    actionClassName = "btn-action btn-icon btn-green",
}) => {
    return (
        <div
            className={`horizontal-banner ${themes[theme]} ${noBorder && "--no-border"} ${square && "--square"} ${className}`}
        >
            {icon && (
                <div className="icon_wrapper">
                    <i className={`icon ${icon}`}></i>
                </div>
            )}

            <div className="content">
                <div className="title">{title}</div>

                {description && <div className="description">{description}</div>}
            </div>

            {actionText && (
                <a
                    href={actionUrl}
                    className="text-decoration-none"
                >
                    <div className={`action ${actionClassName}`}>{actionText}</div>
                </a>
            )}

            {supportSection && (
                <>
                    {reapplyShown && (
                        <div className="support-section mr-1">
                            <a
                                href={mainHostUrl("apply/re-apply")}
                                className="referrals-btn btn-action btn-text btn-green mr-0"
                            >
                                Re-Apply
                            </a>
                        </div>
                    )}

                    <div className="support-section">
                        <span className="support-icon">
                            <i className="icon-blue-support"></i>
                        </span>
                        <div>
                            <p className="support">Need help?</p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="contact"
                                href="mailto:support@adevait.com"
                            >
                                Contact us at support@adevait.com
                            </a>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default HorizontalBanner;
