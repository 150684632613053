import MainMenu from "./MainMenu";
import { Helmet } from "react-helmet-async";
import React, { useState, useEffect } from "react";

import api from "@app/services/api";
import { Feature } from "@app/components";
import { generate } from "@app/routes/SidebarLinks/Talent";
import { FEAT_NEW_TALENT_DASHBOARD_ENABLED } from "@app/config/features";

import ScheduleACallBox from "../ScheduleACallBox";

function generateSidebarLinks(user) {
    return generate({
        isCoreEmployee: user?.is_core_employee || false,
        dashboardAccessOnly:
            user?.current_status?.polarity === -1 ||
            user?.current_status?.code === "APP_NEW" ||
            user?.current_status?.code === "APP_CLOSED",
        applicantAccessOnly:
            user?.current_status?.code === "APP_ON_HOLD" ||
            user?.current_status?.code === "APP_INITIAL_CALL" ||
            user?.current_status?.code === "APP_INITIAL_CALL_SCH" ||
            user?.current_status?.code === "APP_TECH_ASSIGNMENT" ||
            user?.current_status?.code === "APP_TECH_INTERVIEW" ||
            user?.current_status?.code === "APP_TECH_CALL_SCH",
    });
}

async function getAccountManager() {
    return await api.get("talent/account-manager");
}

function TalentSidebar({ toggleResponsiveMenu }) {
    const [user, setUser] = useState([]);
    const [sidebarLinks, setSidebarLinks] = useState([]);

    useEffect(() => {
        getAccountManager().then((result) => {
            setUser(result.data.data);
            setSidebarLinks(generateSidebarLinks(result.data.data));
        });
    }, []);

    return (
        <>
            <Helmet>
                <script
                    type="text/javascript"
                    src="/vendor/calcom.js"
                />
            </Helmet>

            <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ flex: 1 }}>
                    <MainMenu
                        items={sidebarLinks}
                        onClick={toggleResponsiveMenu}
                    />
                </div>

                {user.owner && (
                    <Feature flag={FEAT_NEW_TALENT_DASHBOARD_ENABLED && user.has_active_billable_contracts > 0}>
                        <ScheduleACallBox
                            user={user}
                            owner={user.owner}
                        />
                    </Feature>
                )}
            </div>
        </>
    );
}

export default TalentSidebar;
