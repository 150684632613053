import React from "react";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import { AsyncPaginate } from "react-select-async-paginate";

import api from "@app/services/api";

function prepareSkillsForSelect(skills) {
    return skills
        .map((el) => {
            return {
                value: el?.id,
                label: el?.name || startCase(el?.slug),
            };
        })
        ?.filter((el) => el.value);
}

function buildQuery(search, page, initialValue = null) {
    const limit = initialValue ? initialValue?.length : 100;

    let query = `page=${page}&limit=${limit}&sortBy=id`;

    if (search) {
        query += `&keyword=${encodeURIComponent(search)}`;
    }

    if (initialValue) {
        query += `&ids=${initialValue?.join(",")}`;
    }

    return query;
}

async function loadSkills(search, page) {
    const params = buildQuery(search, page);

    return api.get(`common-data/skills/filtered?${params}`);
}

async function loadInitialValues(search, page, initialValues = null) {
    const values = uniq(initialValues)?.filter(Boolean);

    if (!values || isEmpty(values)) return Promise.resolve([]);

    const params = buildQuery(null, page, initialValues);

    return api.get(`common-data/skills/filtered?${params}`);
}

const customStyles = {
    multiValue: (base) => ({
        ...base,
    }),
    control: (base) => ({
        ...base,
        minHeight: 42,
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 4,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 4,
    }),
    valueContainer: (base) => ({
        ...base,
        padding: "0px 6px",
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
    }),
};

const SkillsSelect = ({ onChange, innerRef, initialValue, ...props }) => {
    const [initialSkills, setInitialSkills] = React.useState([]);
    const [selectedSkills, setSelectedSkills] = React.useState([]);

    const handleChange = (newVal) => {
        setSelectedSkills(newVal);

        onChange(newVal);
    };

    const noOptionsMessage = ({ inputValue }) => {
        if (Boolean(inputValue)) return "No matching skills found";

        return "No skills found";
    };

    const loadOptions = React.useCallback(async (search, loadedOptions, { page }) => {
        const searchRes = await loadSkills(search, page);

        const searchData = prepareSkillsForSelect(searchRes?.data?.data?.data || []);

        const data = uniqBy([...searchData], "value");

        return {
            options: data,
            additional: {
                page: page + 1,
            },
            hasMore: searchRes?.data?.pagination?.current_page < searchRes?.data?.pagination?.last_page,
        };
    }, []);

    const shouldLoadMore = React.useCallback((scrollHeight, clientHeight, scrollTop) => {
        // Require the scroll to be at least 75% of overall scroll area
        const minThreshold = 0.75 * scrollHeight;

        const endOfScroll = scrollHeight - clientHeight;

        // Load new data only if the scroll is in the last quarter of the overall scrollHeight
        // Or if the scrollTop has reached the end of the scroll area
        return scrollTop > minThreshold || scrollTop === endOfScroll;
    }, []);

    // Load initial values (if any)
    React.useEffect(() => {
        loadInitialValues(null, 1, initialValue).then((res) => {
            const data = prepareSkillsForSelect(res?.data?.data?.data || []);

            setInitialSkills((prev) => uniqBy([...prev, ...data], "value"));
            setSelectedSkills((prev) => uniqBy([...prev, ...data], "value"));
        });
    }, [initialValue]);

    return (
        <AsyncPaginate
            isMulti
            defaultOptions
            selectRef={innerRef}
            styles={customStyles}
            debounceTimeout={500}
            value={selectedSkills}
            options={initialSkills}
            onChange={handleChange}
            closeMenuOnSelect={false}
            loadOptions={loadOptions}
            shouldLoadMore={shouldLoadMore}
            noOptionsMessage={noOptionsMessage}
            additional={{
                page: 1,
            }}
            components={{
                IndicatorSeparator: () => null,
            }}
            {...props}
        />
    );
};

export default SkillsSelect;
